import React from "react";
import { graphql } from "gatsby";

import { Seo } from "~/components/seo";
import { Main } from "~/layouts";
import { HeroTitle } from "~/components/hero";
import { Collections } from "~/components/collections";
import { Footer } from "~/components/footer";

const LatestPage = ({ data: { allShopifyProduct } }) => {
  const { nodes: products } = allShopifyProduct || {};
  return (
    <Main fullView>
      <Seo title={`Just In!`} />
      <HeroTitle title="Just In!" />
      <Collections {...{ products }} />
      <Footer />
    </Main>
  );
}

export const query = graphql`
  {
    allShopifyProduct(
      limit: 50,
      sort: {
        fields: publishedAt,
        order: DESC,
      },
    ) {
      nodes {
        id
        slug: handle
        title
        description
        descriptionHtml
        tags
        status
        vendor
        vendorSlug
        images {
          id
          altText
          originalSrc
          src
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        collections {
          slug: handle
        }
      }
    }
  }
`;

export default LatestPage;
